import React, { useEffect, useState } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import Navigation from './mobileNavigation';

export default function Header() {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const location = useLocation(); 
    const navigate = useNavigate();

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const handleClick = () => {
        navigate('/');
    };

    return (
        <div className='header'>
            <div className='companyLogo'>
                <div className='logo' onClick={handleClick}></div>
            </div>
           
            <div className='navigation'>
                {isMobile ? 
                    <Navigation />
                : (
                    <ul>
                        <Link to={'/aboutUs'} className={`link ${location.pathname === '/aboutUs' ? 'active' : ''}`}>
                            <li>About</li>
                        </Link>

                        <Link to={'/contactUs'} className={`link ${location.pathname === '/contactUs' ? 'active' : ''}`}>
                            <li>Contact</li>
                        </Link>

                        <Link to={'/applyNow'} className={`link ${location.pathname === '/applyNow' ? 'active' : ''}`}>
                            <li>Apply</li>
                        </Link>
                        
                        <Link to={'/ourTeam'} className={`link ${location.pathname === '/ourTeam' ? 'active' : ''}`}>
                            <li>Our Team</li>
                        </Link>

                        <Link to={'/suggestions'} className={`link ${location.pathname === '/suggestions' ? 'active' : ''}`}>
                            <li>Suggestions</li>
                        </Link>
                        
                        <Link to={'/events'} className={`link ${location.pathname === '/events' ? 'active' : ''}`}>
                            <li>Events</li>
                        </Link>
                    </ul>
                )}
            </div>
        </div>
    );
}
