import React, { useState, useEffect, useCallback } from "react";



export default function CalculteEventTime({ eventStart, title, description, location }) {
    
    const [timeLeft, setTimeLeft] = useState(0);
    const [eventStarted, setEventStarted] = useState(false);


    // Function to calculate time left
    const handleTimeLeft = useCallback(() => {
        const currTime = new Date().getTime();
        const startTime = new Date(eventStart).getTime();
        const remainingTime = startTime - currTime;

        if (remainingTime <= 0) {
        setTimeLeft(0);
        setEventStarted(true);
        } else {
        setTimeLeft(remainingTime);
        }
    }, [eventStart]);

    useEffect(() => {
        handleTimeLeft();
        const interval = setInterval(handleTimeLeft, 1000);
        return () => clearInterval(interval);
    }, [handleTimeLeft]);

    // Convert milliseconds into a readable format
    const formatTimeLeft = (ms) => {
        const seconds = Math.floor((ms / 1000) % 60);
        const minutes = Math.floor((ms / 1000 / 60) % 60);
        const hours = Math.floor((ms / (1000 * 60 * 60)) % 24);
        const days = Math.floor(ms / (1000 * 60 * 60 * 24));
        return `${days}d ${hours}h ${minutes}m ${seconds}s`;
    };



  
  return (
    <div className={`eventDetail ${eventStarted ? 'eventStarted' : ''}`}>
      {eventStart === 0 ? (
        <p className="eventTime  notLive">N/A.</p>
      ) : eventStarted ? (
        <p className="eventTime evenParagraph">Event is Live!</p>
      ) : (
        <>  

            <span className="timerIcon"></span>
            <p className="timer">{formatTimeLeft(timeLeft)}</p>
          
        </>
      )}

    </div>
);

}