import React, {useContext, useState} from "react";
import CalculteEventTime from "../utility/timer";
// import Person from "./personProp";
import WomenInTechRegistration from "../register/registerForm";
import { UserContext } from "../context/userContext";

export default function EventHandler(){

    const [isModalOpen, setIsModalOpen] = useState(false);

    const {totParticipants, totSeats, eventDate} = useContext(UserContext);



    return(
        <main className="events">
            <div className="eventsMainSection">
                <div className="bannerDetails">
                    <h1 className="headingBanner">TECH QUEENS: CONNECT & ELEVATE</h1>
                    <div className="dateAndLocation">
                        <div className="calendarContainer">
                            <span className="calendarBannerIcon"></span>
                            <p className="dateBanner">23/04/2025</p>
                        </div>
                        <div className="locationContainer">
                            <span className="locationBannerIcon"></span>
                            <p className="locationBanner">QUEEN MARY UNIVERSITY OF LONDON</p>
                        </div>
                        </div>
                </div>

                <div className="registration">
                    <button type="button" disabled={totSeats<=0} className={`registerEvent applyButton ${totSeats <=0 ? 'disable': '' }`} onClick={() => setIsModalOpen(true)}>
                       Register
                    </button>
                </div>

                <WomenInTechRegistration isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />

                <div className="timerSection">
                    <CalculteEventTime 
                    eventStart={eventDate}
                    title={'Women in Tech Networking Event'}
                    description={'inspiring and empowering Women in Tech Networking Event, designed to connect, educate, and uplift women in the tech industry!'}
                    location={'London'}

                    />

                    <div className="eventTimeline">
                        <span className="calendarIcon"></span>
                        <div className="detailsDay">
                            <span className="days otherDetail">23rd April 2025</span>
                            <span className="days otherDetail secondDetail">Duration: 11 am - 3 pm</span>
                        </div>
                  
                    </div>

                    
                    <div className="locationBannerDetails">
                        <span className="locationIcon"></span>
                        <div className="locationDetails">
                            <span className="days otherDetail">Queen Mary University of London</span>
                            <span className="days otherDetail secondDetail">327 Mile End Rd, Bethnal Green, London E1 4NS</span>
                        </div>
                    </div>

                    <div className="participants">
                        <span className="peopleIcon"></span>
                        <div className="participantsDetails">
                            <span className="days otherDetail">{totParticipants} participants</span>
                            <span className="days otherDetail secondDetail">{totSeats} seats left</span>
                        </div>
                    </div>
                </div>
            </div>

   

            <div className="detailsSection">

                <div className="sectionTitle">
                    <span className="eventTitle">Women in Tech Networking Event</span>
                </div>

                <div className="sectionDesc">
                    <p className="eventDesc">
                    Join us for an inspiring and empowering Women in Tech Networking Event, designed to connect, educate, and uplift women in the tech industry! Whether you're an aspiring tech professional, a recent graduate, or an experienced leader looking to grow your network, this event is your opportunity to engage with like-minded women and industry experts.</p>
                    <p className="bulletPoints eventDesc bulletMain"><b>📌 What to Expect:</b></p>
                    
                    <p className="bulletPoints eventDesc"><b>✨ CV Workshop</b> – Get expert tips to craft a standout CV that highlights your skills and experience.</p>
                    <p className="bulletPoints eventDesc"><b>🤝 Networking Lunch </b>– Connect with peers, mentors, and industry professionals in a supportive and engaging environment.</p>
                   
                    <p className="bulletPoints eventDesc"><b>🎤 Panel Discussion </b>– Gain valuable insights from successful women in tech as they share their journeys, challenges, and advice for career growth.</p>

                    <p className="bulletPoints eventDesc">This is more than just a networking event—it's a space to learn, share, and empower each other. Whether you're looking to break into tech, explore new opportunities, or take your career to new heights, you don’t want to miss this chance to build meaningful connections and gain practical knowledge.</p>

                    <p className="bulletPoints eventDesc">🚀 Let’s connect, inspire, and lead the way for women in tech!</p>

                    <p className="bulletPoints eventDesc">🔗 Secure your spot today!</p>
                </div>

                

                {/* <div className="participantsSection">

                    <Person
                        mainClassname={"person"}
                        secondaryClassname={"person1"}
                        thirdClassname={"personName"}
                        personName={"Byte Queen Teams"}

                    />

                    <Person
                        mainClassname={"person"}
                        secondaryClassname={"person2"}
                        thirdClassname={"personName"}
                        personName={"Person 2"}

                    />
    
                    <Person
                        mainClassname={"person"}
                        secondaryClassname={"person3"}
                        thirdClassname={"personName"}
                        personName={"Person 3"}

                    />

                    <Person
                        mainClassname={"person"}
                        secondaryClassname={"person4"}
                        thirdClassname={"personName"}
                        personName={"Person 4"}

                    />

                    <Person
                        mainClassname={"person"}
                        secondaryClassname={"person5"}
                        thirdClassname={"personName"}
                        personName={"Person 5"}

                    />

                </div> */}

            </div>
           


        </main>
    )
}