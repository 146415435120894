import React from "react";
import Header from "../navigation/header";
import Footer from "../navigation/footer";
import EventHandler from "./eventsHandler";

export default function EventsPage(){
    return(
       <>
        <Header />
        <EventHandler />
        <Footer />
       </>
    )
}