import React, { useContext, useState, useEffect } from 'react';
import { Link, useLocation} from 'react-router-dom';
import { UserContext } from '../context/userContext';

export default function Navigation() {
  const [isOpen, setIsOpen] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const {isTouched, setIsTouched} = useContext(UserContext);
  const location = useLocation();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    setIsClicked(!isClicked);
  };

  const handleMenu = (path) =>{
    (location.pathname === path) && setIsOpen(!isOpen)
  }

  useEffect(() => {
    if (isTouched) {
        setIsOpen(false); 
        setIsTouched(false);
        setIsClicked(false);
    }
}, [isTouched, setIsTouched]);

  return (
    <>
      <button onClick={toggleMenu} className={isClicked? "buttonStyle":"btnStyle"}>
        ☰
      </button>

      {isOpen && (
        <div className={`menu ${isOpen ? 'open' : ''}`}>
          <ul>
            <Link to={'/'} className='link resLink' onClick={()=>handleMenu('/')}>
              <li>Home</li>
            </Link>

            <Link to={'/aboutUs'} className='link resLink' onClick={()=>handleMenu('/aboutUs')}>
              <li>About</li>
            </Link>

            <Link to={'/contactUs'} className='link resLink' onClick={()=>handleMenu('/contactUs')}>
              <li>Contact</li>
            </Link>

            <Link to={'/applyNow'} className='link resLink' onClick={()=>handleMenu('/applyNow')}>
              <li>Apply</li>
            </Link>

            <Link to={'/ourTeam'} className='link resLink' onClick={()=>handleMenu('/ourTeam')}>
              <li>Our Team</li>
            </Link>

            <Link to={'/suggestions'} className='link resLink' onClick={()=>handleMenu('/suggestions')}>
              <li>Suggestion</li>
            </Link>

            <Link to={'/events'} className='link resLink' onClick={()=>handleMenu('/events')}>
              <li>Events</li>
            </Link>
          </ul>
        </div>
      )}
    </>
  );
}
