import React, { useContext } from "react";
import Who from "./who";
import How from "./how";
import Why from "./why";
import { UserContext } from "../context/userContext";
// import { members } from "../data/teamData";


export default function AboutUs(){
    
    const {handleTouch} = useContext(UserContext);
    // const ceo = members.find((el) => el.role === "CEO");
    
    return(
        <main className="aboutus" onClick={handleTouch}>
            <div>
                <span className="title aboutTitle">Your Success, Our Commitment!</span>
            </div>

            <div className="aboutContainer">
                <div className="cardPicture">
                    {/* <img src={ceo.image} alt={ceo.name} className="ceoCard" /> */}
                    <img src='/images/logo.png' alt='company logo' className="ceoCard" />

                </div>

                <div className="aboutDetail">
                    <Who/>
                    <How />
                    <Why />
                </div>
            
            </div>
        </main>
    )
}