import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import UserProvider from "./context/userContext";
import HomePage from './home/homePage';
import ApplyPage from './apply/applyPage';
import ContactUsPage from './contact/contactPage';
import AboutUsPage from './about/aboutUsPage';
import OurTeamPage from './team/ourTeamPage';
import SuggestionPage from './suggestion/suggestionPage';
import EventsPage from './events/eventsPage';



export default function App() {
  return (
    <>
    <UserProvider>
      <BrowserRouter>
        <Routes>
          
          <Route exact path='/' element={<HomePage />}></Route>
    
          <Route exact path='/applyNow' element={<ApplyPage />}></Route>

          <Route exact path='/contactUs' element={<ContactUsPage />}></Route>

          <Route exact path='/aboutUs' element={<AboutUsPage />}></Route>

          <Route exact path='/ourTeam' element={<OurTeamPage />}></Route>

          <Route exact path='/suggestions' element={<SuggestionPage/>}></Route>

          <Route exact path='/events' element={<EventsPage/>}></Route>

        </Routes>
      </BrowserRouter>
    </UserProvider>
    </>
  );
}

;
