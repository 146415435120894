import React, {useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import Input from "../props/input";
import {Subjects} from "../data/subject";
import { postData } from "../fetchRoutes/postData";
import { emailValidation } from "../utility/validation";
import { messageValidation } from "../utility/validation";
import { nameValidation } from "../utility/validation";
import { UserContext } from "../context/userContext";


export default function SuggestionForm(){
    // const apiUrl = process.env.REACT_APP_API_URL;
    const apiUrl = 'http://localhost:8000/api/users/suggestions';

    const [selectedSubject, setSelectedSubject] = useState('');

    const [isValid, setIsValid] = useState({
        email:true,
        message:true,
        fullName: true,
        selectedSubject: true
    });

    const [isLoading, setIsLoading] = useState(false);

    const [isSubmitted, setIsSubmitted] = useState(false);

    const navigate = useNavigate();

    const [suggestion, setSuggestion] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });

    const {handleTouch} = useContext(UserContext);

    const handleSubjectChange = (event) => {
        setSelectedSubject(event.target.value);
        setSuggestion({ ...suggestion, subject: event.target.value });

      };

    const handleChange = (event) => {
        const {name, value} = event.target;
        setSuggestion({...suggestion, [name]:value});
        if (name === 'email' ) {
            setIsValid(prevIsValid => ({
                ...prevIsValid,
                email: emailValidation(value)
            }));
        }
        if(name === 'message'){
            setIsValid(prevIsValid => ({
                ...prevIsValid,
                message: messageValidation(value)
            }));
        }
        if (name==='name'){
            setIsValid(prevIsValid =>({
                ...prevIsValid,
                fullName: nameValidation(value)
            }));
        }
        
    }

    
    const handleClick = (event) => {
        event.preventDefault();

        const allValid = Object.values(isValid).every(value => value);

        if (!allValid) {
            return; 
        }
        setIsLoading(true);

        
        postData(apiUrl, suggestion)
            .then((response) => {
                if (response.status ==='success'){  
                        setIsLoading(false);
                        setIsSubmitted(true);

                setTimeout(() => {
                    navigate('/');
                }, 2000);
                }else if (response.status === 'error' && response.errors) {
                    setIsValid((prevIsValid) => {
                        const newValidationState = { ...prevIsValid };
    
                        for (const [field] of Object.entries(response.errors)) {
                            if (field in newValidationState) {
                                newValidationState[field] = false; 
                            }
                        }
    
                        return newValidationState;
                    });
    
                    setIsLoading(false);
                }
            })
            .catch(error => {
                setIsLoading(false);
                // console.error('Error:', error);
            });
        
    };

    
    return(
        <main onClick={handleTouch} className="suggestion">
            <div className="suggestionForm">
                <div className="title">
                    <h1 className="formHeading">Suggestion Form</h1>
                </div>

                <form className="form">
                    <div className="suggestionName">
                        <label className="selectSubject">Name:</label>
                        <Input 
                            className={`nameSuggestion ${isValid.fullName ? '' : 'input-error'}`}
                            type="text"
                            name="name"
                            placeholder="Full Name"
                            autoCapitalize="off"
                            autoCorrect="off"
                            required 
                            value={suggestion.name}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="suggestionEmail">
                        <label className="selectSubject">Email:</label>
                        <Input 
                            className={`email ${isValid.email ? '' : 'input-error'}`}
                            type="text"
                            name="email"
                            placeholder="example@gmail.com"
                            autoCapitalize="off"
                            autoCorrect="off"
                            required 
                            value={suggestion.email}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="suggestionSubjects">
                        <div className="subjectTitle">
                            <label className="selectSubject">Select a Subject</label>
                        </div>
                        <div className="subjectDropdown">
                            <select value={selectedSubject} onChange={handleSubjectChange} className="selectOption">
                                <option value="" disabled >Select a subject</option>
                                {Subjects.map((subject, index) => (
                                    <option key={index} value={subject} >{subject}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="suggestionMessage">
                        <label className="selectSubject">Message:</label>
                        <textarea 
                           className={`message ${isValid.message ? '' : 'input-error'}`}
                            type="text"
                            name="message"
                            placeholder="Write your suggestion here"
                            autoCapitalize="off"
                            autoCorrect="off"
                            required 
                            value={suggestion.message}
                            onChange={handleChange}
                        />
                    </div>
                    
                    <div className="submitButton">
                        <button 
                            className='submit' 
                            type='submit'
                            onClick={handleClick}
                        >
                            {isLoading && isValid ? 'Sending...' : 'Submit'} 

                        </button>  
                    </div>                 
                </form>
                
                    {isLoading && isValid && (
                        <div className="loader-container">
                            <div className="loader"> <br></br>🌀 Sending your thoughts to the tech gods...</div>
                        </div>
                    )
                    }

                    {isSubmitted && (
                        <div className="modal success-modal">
                            <div className="modal-content">
                                <span className="tick">✔️</span>
                                <p>Your form has been submitted successfully!</p>
                            </div>
                        </div>
                    )}
                
                <div className="note">
                        <p>NOTE: By submitting, you consent to your email being shared with Byte Queens.</p>
                </div>
            </div>
        </main>
      
    )
}