import React, {createContext, useEffect, useState} from "react";
import { getData } from "../fetchRoutes/getData";

export const UserContext = createContext();

export default function UserProvider(props){
    
    const apiUrl = process.env.REACT_APP_API_GET_EVENT_URL;

    // const apiUrl = 'http://localhost:8000/api/users/getParticipants';

    const[isTouched, setIsTouched] = useState(false);

    const[totParticipants, setTotParticipants] = useState(0);
    const[totSeats, setTotSeats]=useState(0);
    const[eventDate, setEventDate] = useState('');


    const handleTouch = () => {
        setIsTouched(prev => !prev);
    };

    useEffect(()=>{
        const fetchEvent = async () => {
            if (!apiUrl) return;
          
            try {
                const response = await getData(apiUrl);
                if (response.status === 'success') {
                    setTotParticipants(response.event.currentParticipants);
                    setTotSeats(response.event.totSeats);
                    setEventDate(response.event.time);
                }
            } catch (err) {
                console.error('Error fetching petitions:', err);
            } 
        };
        fetchEvent();
    },[apiUrl]);
  


    return (
        <UserContext.Provider value={{
            isTouched, 
            setIsTouched, 
            handleTouch,
            totParticipants,
            setTotParticipants,
            totSeats,
            setTotSeats,
            eventDate, 
            setEventDate}}>
            {props.children}
        </UserContext.Provider>
    );
    
}