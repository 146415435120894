import React, { useState, useContext, useEffect } from "react"; // Import useEffect
import { useNavigate } from "react-router-dom";
import Input from "../props/input";
import { postData } from "../fetchRoutes/postData";
import { emailValidation, nameValidation } from "../utility/validation";
import { UserContext } from "../context/userContext";

export default function WomenInTechRegistration({ isOpen, onClose }) {
    // const apiUrl = 'http://localhost:8000/api/users/register';
    const apiUrl = process.env.REACT_APP_API_REGISTER_URL;


    const [isValid, setIsValid] = useState({
        email: true,
        fullName: true,
        degree: true
    });

    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const navigate = useNavigate();

    const [registration, setRegistration] = useState({
        name: '',
        email: '',
        degree: ''
    });

    const { handleTouch, setTotParticipants, setTotSeats } = useContext(UserContext);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setRegistration({ ...registration, [name]: value });

        if (name === 'email') {
            setIsValid(prev => ({ ...prev, email: emailValidation(value) }));
        }
        if (name === 'name') {
            setIsValid(prev => ({ ...prev, fullName: nameValidation(value) }));
        }
        if (name === 'degree') {
            setIsValid(prev => ({ ...prev, degree: value.trim() !== '' }));
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        if (!Object.values(isValid).every(value => value)) return;

        setIsLoading(true);
        setErrorMessage(null);

        postData(apiUrl, registration)
            .then((response) => {
                setIsLoading(false);
                if (response.status === 'success') {
                    setIsSubmitted(true);
                    setTotSeats(response.event.totSeats);
                    setTotParticipants(response.event.currentParticipants);
                    
                } else {
                    setErrorMessage(response.error);
                }
            })
            .catch(() => {
                setIsLoading(false);
                setErrorMessage("Hold up! You're already rocking the Byte Queens vibe!");
                setTimeout(() => {
                    navigate('/');
                }, 2000);
            });
    };

    // Navigate to events page if errorMessage is set
    useEffect(() => {
        if (errorMessage) {
            // Set a timeout for how long the message will be displayed
            const timer = setTimeout(() => {
                navigate('/events'); // Navigate to the events page after a delay
            }, 3000); // Adjust the delay as needed (e.g., 3000 ms = 3 seconds)

            return () => clearTimeout(timer); // Cleanup the timeout on unmount
        }
    }, [errorMessage, navigate]);

    if (!isOpen) return null;

    return (
        <div onClick={handleTouch} className="modal-overlay">
            <div className="modal-content">
                <span className="close-button" onClick={onClose}>&times;</span>
                <h2 className="modal-title">Women in Tech Registration</h2>

                {isSubmitted ? (
                    <div className="success-message">
                        <p>🎉 Registration successful!</p>
                    </div>
                ) : (
                    <>
                        {errorMessage && (
                            <div className="error-message">
                                <p>{errorMessage}</p> {/* Display error message here */}
                            </div>
                        )}
                        <form onSubmit={handleSubmit} className="form">
                            <div className="suggestionName registerName">
                                <label className="selectSubject registerLabel">Name:</label>
                                <Input 
                                    className={`nameSuggestion registerName ${isValid.fullName ? '' : 'input-error'}`}
                                    type="text"
                                    name="name"
                                    placeholder="Full Name"
                                    required
                                    autoCapitalize="off"
                                    autoCorrect="off" 
                                    value={registration.name}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="suggestionEmail registerEmail">
                                <label className="selectSubject registerLabel">Email:</label>
                                <Input 
                                    className={`email registerEmail ${isValid.email ? '' : 'input-error'}`}
                                    type="email"
                                    name="email"
                                    placeholder="example@gmail.com"
                                    autoCapitalize="off"
                                    autoCorrect="off"
                                    required 
                                    value={registration.email}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="suggestionEmail registerDegree">
                                <label className="selectSubject registerLabel">Degree Enrolled In:</label>
                                <Input 
                                    className={`nameSuggestion registerDegree ${isValid.degree ? '' : 'input-error'}`}
                                    type="text"
                                    name="degree"
                                    placeholder="e.g., Computer Science"
                                    required
                                    autoCapitalize="off"
                                    autoCorrect="off" 
                                    value={registration.degree}
                                    onChange={handleChange}
                                />
                            </div>

                            <button type="submit" className="submit registerMe">
                                {isLoading ? 'Submitting...' : 'Register'}
                            </button>
                        </form>
                    </>
                )}
            </div>
        </div>
    );
}
